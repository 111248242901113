<template>
    <div>
        <template>
            <v-btn @click="dialog = true">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </template>

        <v-dialog v-model="dialog" max-width="1120">

            <v-card class="modal-card">
                <div class=" modal-header">
                    <h3 class="modal-title">Личные данные</h3>
                    <v-btn @click="dialog = false" icon><v-icon>mdi-close</v-icon></v-btn>
                </div>

                <div class="modal-container">
                    <div class="card-item">
                        <div class="avatar-container">
                            <img src="../assets/icons/Frame 7654.svg" alt="">
                            <p class="card-username">Сабина</p>

                        </div>
                        <div class="card-info">
                            <div class="user-column">
                                <img class="small-icons" src="../assets/img/Users.svg" alt="">
                                <span class="card-line"></span>
                            </div>
                            <div class="phone-container">
                                <p class="card-label">Телефон</p>
                                <p class="card-text">+7 747 500 12 46</p>
                            </div>
                            <div class="gender-container">
                                <p class="card-label">Пол</p>
                                <p class="card-text">Женский</p>
                            </div>
                            <div class="birthday-container">
                                <p class="card-label">Дата рождения</p>
                                <p class="card-text">19.05.1991</p>
                            </div>
                            <div class="user-column">
                                <img class="small-icons" src="../assets/img/iPhone.svg" alt="">
                                <span class="card-line"></span>
                            </div>
                            <div class="register-container">
                                <p class="card-label">Дата регистрации</p>
                                <p class="card-text">01.05.2024</p>
                            </div>

                            <div class="user-column">
                                <img class="small-icons" src="../assets/img/Bag 3.svg" alt="">
                                <span class="card-line"></span>
                            </div>
                            <div class="first-order-container">
                                <p class="card-label">Дата первой покупки</p>
                                <p class="card-text">05.05.2024</p>
                            </div>

                        </div>
                    </div>

                    <div class="card-item-second">

                        <div style="display:flex; align-items: center; gap: 14px;">
                            <img src="../assets/img/Union.svg" alt="">
                            <p class="card2-title">Лояльность</p>
                        </div>
                        <span class="card-line" style="width: 360px;"></span>

                        <div class="card-bonus">
                            <p class="bonus-points">2500</p>
                            <img src="../assets/img/Group 7718.svg" alt="">
                        </div>
                        <p class="card-cashback">кэшбек 5% с каждого заказа</p>
                        <div>
                            <div class="bar-container">
                                <div class="price-circle"></div>

                                <div class="progress-bar">
                                    <div class="progress-bar-fill" :style="{ width: progressValue + '%' }"></div>
                                </div>

                                <div class="price-circle-gray"></div>

                            </div>

                            <div class="price-container">
                                <p>
                                    {{ formattedLevelStart }} тг
                                </p>
                                <p>
                                    {{ formattedLevelEnd }} тг
                                </p>
                            </div>

                            <p class="price-remains">Осталось потратить еще {{ remainingAmount }} тг, что бы увеличить
                                постоянный
                                кэшбек до
                                {{ nextLevel }}</p>
                        </div>
                        <div>
                            <h4 class="cashback-title">Уровни кэшбека</h4>
                            <div style="display: flex; margin-top: 10px;">
                                <div class="cashback-prices">
                                    <p>до 1 000 000 тг</p>
                                    <p>до 500 000 тг</p>
                                    <p>от 100 000 тг</p>
                                    <p>от 0 тг</p>
                                </div>
                                <div class="chart-container">
                                    <div>
                                        <div class="chart-circle-container">

                                            <div class="chart-circle-first">
                                                <img src="../assets/img/Group 7852.svg" alt="">
                                                <!-- <div class="chart-circle-gray"></div> -->
                                            </div>
                                            <div class="chart-circle-second">
                                                <img src="../assets/img/Group 7852.svg" alt="">
                                                <!-- <div class="chart-circle-gray"></div> -->
                                            </div>
                                            <div class="chart-circle-third">
                                                <!-- <img src="../assets/img/Group 7852.svg" alt=""> -->
                                                <div class="chart-circle-gray"></div>
                                            </div>
                                            <div class="chart-circle-fourth">
                                                <!-- <img src="../assets/img/Group 7852.svg" alt=""> -->
                                                <div class="chart-circle-gray"></div>
                                            </div>
                                        </div>
                                        <div class="cashback-line-gray"></div>
                                        <div class="cashback-line-fill" :style="{ height: verticalBar + 'px' }"></div>
                                    </div>
                                </div>
                                <div>
                                    <div class="cashback-info">
                                        <div>
                                            <h4>10%</h4>
                                            <p>на все заказы, когда вы потратите еще 250 000 тг </p>
                                        </div>

                                        <div>
                                            <h4>7%</h4>
                                            <p>на все заказы, когда вы потратите еще 117 000 тг </p>
                                        </div>

                                        <div>
                                            <h4>5%</h4>
                                            <p>постоянный кэшбек с каждой оплаты</p>
                                        </div>


                                        <div>
                                            <h4>3%</h4>
                                            <p>уровень достигнут</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="third-container">
                        <div class="card-item-3">
                            <div class="title-container">
                                <div class="d-flex align-center flex-drirection-row">
                                    <img src="../assets/img/delivery-small.svg" alt="">
                                    <p class="title-name">Статистика</p>
                                </div>
                                <div class="d-flex align-center flex-drirection-row">
                                    <p class="review-title">Отзывы</p>
                                    <div class="review-container">
                                        <img class="review-img" src="../assets/img/like-small.svg" alt="">
                                        <span class="review-text">100</span>
                                    </div>




                                </div>
                            </div>
                            <div class="divider-modal"></div>
                            <div class="d-flex date-containers">
                                <div class="d-flex flex-column date-container-1">
                                    <p class="order-1">Дата последнего
                                        заказа</p>
                                    <p class="date-order">07.10.2024</p>
                                </div>
                                <div class="d-flex flex-column date-container-2">
                                    <p class="order-1">Дата последней
                                        доставки</p>
                                    <p class="date-order">01.09.2024</p>
                                </div>
                            </div>
                            <div class="order-info-container">
                                <div class="order-info-containers">
                                    <p class="order-info-text-green">Кол-во</p>
                                    <p class="order-info-text-green">Сумма</p>
                                </div>
                                <div class="divider-modal"></div>

                                <div class="mt-2">
                                    <p class="order-title">Всего заказов</p>
                                    <div class="order-info-containers-1">
                                        <p class="order-info-text-second">10</p>
                                        <p class="order-info-text-second">101 588 ₸</p>
                                    </div>
                                </div>
                                <div class="divider-modal mt-0"></div>
                                <div class="mt-2">
                                    <p class="order-title">В зале</p>
                                    <div class="order-info-containers-1">
                                        <p class="order-info-text-second">10</p>
                                        <p class="order-info-text-second">101 588 ₸</p>
                                    </div>
                                </div>
                                <div class="divider-modal mt-0 "></div>
                                <div class="mt-2">
                                    <p class="order-title">Доставка</p>
                                    <div class="order-info-containers-1">
                                        <p class="order-info-text-second">10</p>
                                        <p class="order-info-text-second">101 588 ₸</p>
                                    </div>
                                </div>
                                <div class="divider-modal mt-0 "></div>


                            </div>
                        </div>

                        <div class="card-item-4">
                            <div class="title-container">
                                <div class="d-flex align-center flex-drirection-row">
                                    <img src="../assets/img/map-location.svg" alt="">
                                    <p class="title-name">Адреса доставки</p>
                                </div>

                            </div>
                            <div class="divider-modal"></div>
                            <div class="location-container" id="style-2">
                                <div class="location-containers">
                                    <p class="location-name">Работа</p>
                                    <p class="location-adress">ул. Богенбай Батыра, 54 </p>
                                    <p class="location-info">офис, этаж 2. Вход с Богенбай батыра</p>
                                </div>
                                <div class="location-containers">
                                    <p class="location-name">Дом</p>
                                    <p class="location-adress">ул. Богенбай Батыра, 54 </p>
                                    <p class="location-info">офис, этаж 2. Вход с Богенбай батыра</p>
                                </div>
                                <div class="location-containers">
                                    <p class="location-name">Офис</p>
                                    <p class="location-adress">ул. Богенбай Батыра, 54 </p>
                                    <p class="location-info">офис, этаж 2. Вход с Богенбай батыра</p>
                                </div>
                                <div class="location-containers">
                                    <p class="location-name">Дача</p>
                                    <p class="location-adress">ул. Богенбай Батыра, 54 </p>
                                    <p class="location-info">офис, этаж 2. Вход с Богенбай батыра</p>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<style scoped>
.progress-bar {
    width: 100%;
    height: 4px;
    background-color: #98a2b2;
    /* Серый цвет */
    /* border-radius: 10px; */
    overflow: hidden;
}

.progress-bar-fill {
    height: 100%;
    background-color: #323c4d;
    /* Красный цвет заполнения */
    width: 0;
    transition: width 0.3s ease;
}

.card-bonus {
    display: flex;
    align-items: center;
}

.bonus-points {
    font-family: Inter;
    font-size: 30.62px;
    font-weight: 700;
    /* line-height: 37.06px; */
    text-align: left;
    color: #323C4D;
    margin-bottom: 0px;
}

.first-order-container {
    margin-top: 10px;
}

.register-container {
    margin-top: 14px;
    margin-bottom: 27px;
}

.birthday-container {
    margin-bottom: 21px;
}

.gender-container {
    margin-bottom: 15px;
}

.phone-container {
    margin-top: 12px;
    margin-bottom: 15px;
}

.small-icons {
    width: 20px;
    height: 20px;
}

.user-column {
    display: flex;
    flex-direction: column;
}

.avatar-container {
    align-items: center;
    display: flex;
    flex-direction: column;
}

#style-2::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

#style-2::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

#style-2::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #606c80;
}

.location-containers {
    margin-bottom: 19px;
    display: flex;
    margin-top: 12px;
    flex-direction: column;


}

.location-container {

    overflow-y: auto;


}

.location-info {
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    color: #606c80;
    margin-bottom: 0px;
}

.location-adress {
    font-family: Inter;
    font-weight: 500;
    font-size: 15px;
    color: #323c4d;
    margin-bottom: 0px;
}

.location-name {
    font-family: Inter;
    font-weight: 700;
    font-size: 15px;
    color: #323c4d;
    margin-bottom: 7px;
}

.third-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 388px;
}

.order-title {
    font-family: Inter;
    font-weight: 600;
    font-size: 16px;
    color: #606c80;
    margin-bottom: 0px;
}

.order-info-text-second {
    font-family: Inter;
    font-weight: 700;
    font-size: 24px;
    /* text-align: right; */
    color: #323c4d;
    margin-bottom: 0px;
}

.order-info-containers {
    display: flex;
    justify-content: space-between;
}

.order-info-containers-1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
}

.order-info-text-green {
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    color: #00b800;
    margin-bottom: 0px;
    padding: 0 10px;

}

.order-info-container {
    margin-top: 24px;
}

.date-containers {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
}

.date-container-1 {
    max-width: 121px;
    width: 100%;
}

.date-container-2 {
    max-width: 115px;
    width: 100%;
    margin-right: 74px;
}


.date-order {
    font-family: inter;
    font-weight: 700;
    font-size: 15px;
    color: #323c4d;
    margin-bottom: 0px;
}

.order-1 {
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    color: #606c80;
    margin-bottom: 0px;
}

.divider-modal {
    background: #d4d4e1;
    height: 1px;
    width: 100%;
    margin-top: 6.8px;

    /* Расширяем на всю ширину родительского контейнера */
}

.review-img {
    width: 22px;
    padding-right: 5.8px;
}

.review-container {
    display: flex;
    align-items: center;
}

.review-text {

    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    color: #606c80;

}

.review-title {
    font-family: Inter;
    font-weight: 600;
    font-size: 12px;
    color: #606c80;
    margin-bottom: 0px;
    padding-right: 10px;
}

.title-name {
    font-family: Inter;
    font-weight: 500;
    font-size: 15pxem;
    color: #323c4d;
    margin-bottom: 0px;
    padding-left: 14px;
}

.title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-card {
    padding: 10px 26px 38px;
    background-color: #F8F8F8 !important;
}

.card-username {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    /* line-height: 19.36px; */
    text-align: center;
    margin-bottom: 0px;
    margin-top: 8px;

}

.card-item-second {
    background: #fff;
    max-width: 388px;
    width: 100%;
    border-radius: 5px;
    /* height: 620px; */
    display: flex;
    flex-direction: column;
    padding: 15px 13px 73px;

}

.card-item {
    background: #fff;
    /* max-width: 247px; */
    width: 388px;
    border-radius: 5px;
    /* height: 620px; */
    display: flex;
    flex-direction: column;
    padding: 15px 25px 73px;

}

.card-item-3 {
    background: #fff;
    width: 100%;
    max-width: 388px;
    /* height: 35.2777777778vh; */
    display: flex;
    flex-direction: column;
    padding: 10px 13px 21px 15px;
    border-radius: 5px;
}

.card-item-4 {
    background: #fff;
    width: 100%;
    max-width: 388px;
    height: 224px;
    display: flex;
    flex-direction: column;
    padding: 10px 13px 21px 15px;
    border-radius: 5px;
    margin-top: 15px;
}

.card2-title {
    margin: 0;
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    line-height: 18.15px;
    text-align: left;

}



.card-cashback {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #98A2B2;
}

.bar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.price-circle {

    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #323C4D;

}

.price-circle-gray {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #98A2B2;
}

.price-line-gray {
    background-color: #98A2B2;

    width: 347px;
    height: 2px;
    margin-top: -8px;

}

.price-line-fill {
    width: 260px;
    height: 5px;
    background-color: #323C4D;
    border-radius: 4px;

    margin-top: -4px;
}

.price-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
}

.price-container p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    color: #98A2B2;
}

.price-remains {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;

    color: #98A2B2;
}



.card-label {
    color: #606C80;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    /* line-height: 2px; */
    text-align: left;
    /* margin-top: 8px; */
    margin-bottom: 5px;

}

.card-text {
    color: #333333;
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 18.15px;
    text-align: left;
    margin-bottom: 0px;
}

.card-line {
    background: #d4d4e1;
    height: 1px;
    width: 100%;
    margin-top: 6.8px;
}

.modal-title {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 29.05px;
    text-align: left;

}

.modal-header {
    padding: 16px 5px 0px 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-container {
    display: flex;
    /* align-items: center; */
    width: 100%;
    /* height: 100%; */
    gap: 20px;
    padding: 15px;
}

::v-deep .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 100%;
}

::v-deep .v-dialog {
    margin: 0px;
}

.cashback-title {
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    line-height: 18.15px;
    text-align: left;

    color: #323C4D;
}

.chart-container {
    position: relative;
}

.chart-circle-container {
    position: absolute;
    display: flex;
    flex-direction: column-reverse;

    left: -12.5px;
}

.chart-circle-gray {
    width: 15px;
    height: 15px;
    margin-left: 6px;
    border-radius: 50%;
    background-color: #98A2B2;
}

.chart-circle-container div {
    margin-bottom: 40px;
}

.cashback-line-gray {
    width: 3px;
    height: 280px;
    background-color: #9F9F9F;
}

.cashback-line-fill {
    /* position: relative; */
    /* top: 90px; */
    margin-top: -200px;
    width: 6px;

    margin-left: -1.5px;
    border-radius: 4px;
    background-color: #323C4D;
}

.cashback-prices {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 15px;
    width: 50%;
}

.cashback-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 10px;
}

.cashback-info div {
    display: flex;
    align-items: center;
    margin-bottom: 60px;
    margin-left: 12.5px;
}

.cashback-info h4 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    margin-left: 5px;
}

.cashback-info p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    margin: 0;
    margin-left: 5px;

    color: #606C80;
}

.cashback-prices p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    /* line-height: 0px; */
    text-align: left;
    margin-bottom: 72.5px;

    /* margin: 0; */
    color: #606C80;
}





@media (min-width: 1264px) {
    .container {
        max-width: 100% !important;
    }
}

@media (min-width: 960px) {
    .container {
        max-width: 100% !important;
    }
}
</style>

<script>
export default {
    name: "dialogWindow",

    watch: {
    },

    methods: {

    },
    computed: {
        formattedLevelStart() {
            // Преобразуем строку в число и применяем форматирование
            return Number(this.currentLevelStart).toLocaleString();

        },
        formattedLevelEnd() {
            // Преобразуем строку в число и применяем форматирование
            return Number(this.currentLevelEnd).toLocaleString();

        },
        formattedMoneySpend() {
            // Преобразуем строку в число и применяем форматирование
            return Number(this.currentMoneySpend).toLocaleString();

        },
        // Расчет процента прогресса от 0 до currentLevelEnd
        progressValue() {
            const end = parseInt(this.currentLevelEnd, 10);
            const spent = parseInt(this.currentMoneySpend, 10);

            // Вычисляем процент прогресса относительно всего диапазона (0 до currentLevelEnd)
            const percent = (spent / end) * 100;

            // Убедитесь, что значение находится в пределах 0-100
            return Math.max(0, Math.min(100, percent));
        },
        // Вычисляем оставшуюся сумму до конца уровня
        remainingAmount() {
            const end = parseInt(this.currentLevelEnd, 10);
            const spent = parseInt(this.currentMoneySpend, 10);
            const remaining = end - spent; // Остаток до currentLevelEnd
            return remaining.toLocaleString(); // Преобразуем в строку с разделителями
        }


    },



    data: () => ({
        dialog: false,
        file: null,
        image: null,
        type: null,
        verticalBar: 200,
        nextLevel: "7%",
        currentLevelStart: '100000',
        currentLevelEnd: "500000",
        currentMoneySpend: "300000"
    }),
};
</script>
